.content-service-1-wrap {
    display: flex;
    flex-direction: column;
}
.content-service-1-wrap > h4 {
    color: #404040;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
}
.content-service-1-wrap > div {
    height: 20px;
}
