.bitcoin-price-container {
  padding-top: 10%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.bitcoin-price-title {
    font-size: 30px;
    font-weight: 800;
    margin-bottom: 40px;
}
.bitcoin-price-wrap {
    width: 90vw;
    height: 60vh;
}
@media (max-width: 1050px) {
    .bitcoin-price-container {
      padding-top: 15%;
      
    }
}
@media (max-width: 800px) {
    .bitcoin-price-container {
      padding-top: 20%;
      
    }
}
@media (max-width: 550px) {
    .bitcoin-price-container {
      padding-top: 25%;
      
    }
    .bitcoin-price-title{
        font-size: 25px;
        margin-bottom: 30px;
    }
}
@media (max-width: 450px) {
    .bitcoin-price-container {
      padding-top: 30%;
      
    }
    .bitcoin-price-title{
        font-size: 22.5px;
        margin-bottom: 30px;
    }
}
@media (max-width: 360px) {
    .bitcoin-price-container {
      padding-top: 30%;
      
    }
    .bitcoin-price-title{
        font-size: 18.5px;
        margin-bottom: 30px;
    }
}
