.mentions-legales-container{
    display: flex;
    /* justify-content: center; */
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color:rgba(128, 128, 128, 0.384);
    flex-direction: column;
}
.mentions-legales-container > div {
    font-weight: 900;
    padding: 20px;
}
.mentions-legales-container li {
    padding: 20px;
}
.mentions-legales-container span {
    padding: 20px;
    font-weight: 700;
    font-style: italic;
}