.component-navbar {
  position: fixed;
  z-index: 1030;
  display: flex;
  width: 100%;
  height: 6em;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  background-color: blueviolet;
  z-index: 1;
}
.navbar-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}
.navbar-logo-container a:hover {
  opacity: 0.8;
  transition: all 0.6s;
}
.component-navbar img {
  width: 130px;
  height: auto;
}
.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}
.nav-container {
  width: 85%;
  display: flex;
  justify-content: flex-end;
  /* overflow: hidden; */
}
.navbar {
  visibility: visible;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 0%;
  transition: 0.5s;
}
.navbar-active {
  display: flex;
  visibility: visible;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  width: 100%;
  transition: 0.5s ease-in-out;
  padding-right: 0%;
}
.navbar-list-container {
  width: 88.235%;
  display: flex;
  justify-content: center;
}
.navbar-list {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style-type: none;
  width: 55%;
}
.navbar-list li a:hover {
  color: #615f5f;
  transition: all 0.5s;
}
.navbar-list li {
  height: 15px;
  overflow: hidden;
  display: none;
}
.navbar-list li a {
  text-decoration: none;
  color: #fff;
}
.li1 {
  display: flex !important;
}
.li2 {
  display: flex !important;
}
.li3 {
  display: flex !important;
}
.burger {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 15%;
  height: 30px;
}
.burger > a {
  width: 50px;
  height: 20px;
  cursor: pointer;
}
.span {
  display: block;
  width: 26px;
  height: 2px;
  background-color: #fff;
  opacity: 1;
  left: 10px;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  position: relative;
}
.span:nth-child(1) {
  top: 0px;
  position: relative;
}
.span:nth-child(2) {
  top: 7px;
}
.span:nth-child(3) {
  top: 14px;
}
.span1-active:nth-child(1) {
  top: 12px;
  transform: rotate(135deg);
}
.span2-active {
  opacity: 0;
  left: -65px;
}
.span3-active:nth-child(3) {
  top: 8px;
  transform: rotate(-135deg);
}

/* ---------------------------------------------------------------------------------------------- */

.switch-langage-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding: 2px;
  align-items: center;
  justify-content: center;
  right: 1%;
  top: 6%;
  position: fixed;
}
.switch-langage-container div {
  height: 100%;
  width: 100%;
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7em;
}
.switch {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 14px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: rgb(61, 11, 108);
}

input:focus + .slider {
  box-shadow: 0 0 1px rgb(61, 11, 108);
}

input:checked + .slider:before {
  -webkit-transform: translateX(9px);
  -ms-transform: translateX(9px);
  transform: translateX(9px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* ---------------------------------------------------------------------------------------------- */

@media (max-width: 767px) {
  .navbar-container {
    display: none;
  }
  .component-navbar {
    justify-content: center;
    align-items: center;
  }
  .switch-langage-container {
    right: 8%;
  }
}
@media (min-width: 400px) {
  .switch-langage-container{
    /* top:5%;
    right: 4% ; */
  }
}
@media (min-width: 500px) {
  .switch-langage-container{
    right: 10%;
    top: 4%;
    padding: 2px;
  }
}
@media (min-width: 768px) {
  .navbar-list-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .switch-langage-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    padding: 2px;
    align-items: center;
    justify-content: center;
    right: 15%;
    top: 3.15%;
    position: fixed;
  }

}
@media (min-width: 850px) {
  .navbar-list-container {
    width: 90.235%;
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1080px) {
  .navbar-list-container {
    width: 88.235%;
    display: flex;
    justify-content: center;
  }
  .switch-langage-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    padding: 20px;
    align-items: center;
    justify-content: center;
    right: 15%;
    top: 1%;
    position: fixed;
  }
  .switch-langage-container div {
    font-size: 1em;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 28px;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
  }
}
