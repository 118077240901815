.cta-component {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  background-color: #2b2b2b;
}
.cta-wrap {
  width: 100%;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.cta-text {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  width: 80%;
  line-height: 38px;
}
.cta-text strong {
  text-align: center;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  padding-bottom: 6px;
  font-weight: bold;
}
.cta-wrap-button {
  width: 90%;
}
.cta-button {
  background-color: blueviolet;
  border: 1px blueviolet solid;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  letter-spacing: 0.8px;
  border-radius: 3%;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  color: #fff;
  text-decoration: none;
}
.cta-button:hover{
  background-color: transparent;
  border: 1px #fff solid;
  transition: all .6s;
  -moz-transition: all .6s;
	-webkit-transition: all .6s;
}
@media (min-width: 344px) {
  .cta-wrap {
    height: 320px;
  }
}
@media (min-width: 460px) {
  .cta-text {
    width: 70%;
  }
}
@media (min-width: 500px) and (max-width: 560px) {
  .cta-text {
    width: 65%;
  }
}
@media (min-width: 560px) {
  .cta-component {
    height: 400px;
  }
  .cta-wrap {
    height: 260px;
  }
}
@media (min-width: 640px) {
  .cta-component {
    /* height: 200vh; */
  }
  .cta-wrap {
    align-items: flex-start;
    height: 220px;
    width: 95%;
  }
  .cta-text {
    text-align: start;
    width: 95%;
  }
  .cta-wrap-button {
    width: 99%;
    display: flex;
    justify-content: flex-end;
  }
  .cta-button {
    width: 275px;
    height: 93px;
  }
}
@media (min-width: 768px) {
  .cta-wrap {
    height: 230px;
  }
  .cta-text {
    width: 80%;
  }
}
@media (min-width: 1024px) {
  .cta-wrap {
    width: 950px;
  }
  .cta-text {
    width: 700px;
  }
}
@media (min-width: 1200px) {
  .cta-wrap {
    flex-direction: row;
    align-items: center;
    width: 1056px;
  }
  .cta-text {
    width: 70%;
  }
  .cta-wrap-button {
    width: 30%;
  }
}
